import React from 'react';
import { Select } from 'antd';

import IconFont from '~/components/IconFont';

const AntSelect = props => <Select suffixIcon={<IconFont type="icon-arrow_down" />} {...props} />;

AntSelect.Option = Select.Option;

export default AntSelect;
