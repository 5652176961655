import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

const Calendar = ({ info, ...otherProps }) => {
  const { title } = info;
  const { value, onChange, ...other } = otherProps;
  const time = value ? moment(value, 'YYYY-MM-DD') : null;
  const triggerChange = (_, dateStr) => {
    onChange && onChange(dateStr);
  };
  return (
    <DatePicker
      allowClear
      placeholder={`请选择${title}`}
      defaultValue={time}
      format="YYYY-MM-DD"
      onChange={triggerChange}
      style={{ width: '100%' }}
      {...other}
    />
  );
};

export default Calendar;
