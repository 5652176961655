import _ from 'lodash';
import ComponentMapping from './offer_item.json';

export const getItem = identifier => {
  return _.find(ComponentMapping, { identifier }) || {};
};

export const getItemValue = (identifier, value, defaultValue = '') => {
  const componentInfo = _.find(ComponentMapping, { identifier });
  if (componentInfo) {
    return _.get(componentInfo.choices, value, defaultValue);
  }
  return defaultValue;
};

export const getItemKey = (identifier, key, defaultKey = '') => {
  const componentInfo = _.find(ComponentMapping, { identifier });
  if (componentInfo) {
    return (
      Object.entries(componentInfo.choices).find(entries => entries[1].toLowerCase() === key.toLowerCase())?.[0] || null
    );
  }
  return defaultKey;
};

export const getItemChoices = identifier => {
  const componentInfo = _.find(ComponentMapping, { identifier });
  if (componentInfo) {
    return componentInfo.choices;
  }
  return {};
};

export function getArrayChoices(identifierOrItem) {
  const item = typeof identifierOrItem === 'string' ? getItem(identifierOrItem) : identifierOrItem;
  const order = item?.order;
  const choices = Object.entries(item.choices);
  if (order) {
    return choices.sort((a, b) => order.indexOf(a[1]) - order.indexOf(b[1]));
  }
  return choices;
}

export function getTargetYearTerm() {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  let year = currentYear;
  let term = 1;
  if (currentMonth > 9) {
    year = currentYear + 1;
    term = 1;
  }
  if (currentMonth > 6) {
    year = currentYear + 1;
    term = 2;
  }

  return [getItemKey('planyr', year.toString()), term];
}
