import React from 'react';
import { Collapse } from 'antd';

import IconFont from '~/components/IconFont';

const AntCollapse = props => (
  <Collapse
    expandIcon={({ isActive }) => (
      <IconFont
        className="custom-collapse-arrow"
        type="icon-collapse-arrow"
        style={{ fontSize: 20 }}
        rotate={isActive ? 90 : 0}
      />
    )}
    {...props}
  />
);

AntCollapse.Panel = Collapse.Panel;

export default AntCollapse;
