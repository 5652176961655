import React from 'react';
import { Input } from 'antd';

const Textarea = ({ info, ...otherProps }) => {
  const { title } = info;
  return (
    <Input.TextArea allowClear placeholder={`请填写${title}`} autoSize={{ minRows: 1, maxRows: 6 }} {...otherProps} />
  );
};

export default Textarea;
