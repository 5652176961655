import React from 'react';
import { Select } from '~/components/antd';
import { getArrayChoices } from '~/components/FormItems/Utils';

const Radio = ({ info, ...otherProps }) => {
  return (
    <Select allowClear placeholder={`请选择${info.title || ''}`} dropdownMatchSelectWidth={false} {...otherProps}>
      {getArrayChoices(info).map(([k, v]) => (
        <Select.Option key={k} value={k}>
          {v}
        </Select.Option>
      ))}
    </Select>
  );
};

export default Radio;
