import React from 'react';
import { Form } from 'antd';

import radio from './Radio';
import text from './Text';
import number from './Number';
import textarea from './Textarea';
import calendar from './Calendar';
import Switch from './Switch';
import { getItem } from '~/components/FormItems/Utils';
import CustomToolTip from '~/components/FormItems/CustomToolTip';

const itemComponents = {
  radio,
  text,
  number,
  textarea,
  calendar,
  switch: Switch,
};

const OfferFormItem = ({
  name,
  required,
  placeholder,
  children,
  rules = [],
  info = {},
  formItemProps = {},
  itemProps = {},
  noLable,
  ...rest
}) => {
  const itemConfig = { ...getItem(name), ...rest, ...info };
  const { type = 'text', title = '', description = '' } = itemConfig;

  if (placeholder) {
    itemProps.placeholder = placeholder;
  }

  if (itemConfig.type === 'switch') {
    formItemProps.valuePropName = 'checked';
  }

  const Item = itemComponents[type];

  return (
    <Form.Item
      key={name}
      name={name}
      required={required}
      label={noLable ? null : title && <CustomToolTip title={title} prompt={description} />}
      rules={[{ required, message: `请填写${title}` }, ...rules]}
      {...formItemProps}>
      {children || <Item info={itemConfig} {...itemProps} />}
    </Form.Item>
  );
};

export default OfferFormItem;
