import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';

export default ({ title, prompt }) =>
  prompt ? (
    <Tooltip title={prompt} className="flex items-center">
      <span>{title}</span>
      <QuestionCircleOutlined className="ml-0.5" />
    </Tooltip>
  ) : (
    title
  );
